import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Content from "../components/common/Content";
import Post from "../components/common/Post";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <Content>
          <Post title="404: Not Found">
            <p>No one here but us trees.</p>
            <Link to="/">Go home</Link>
          </Post>
        </Content>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
