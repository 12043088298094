import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";
import MarkdownContent from "../MarkdownContent";
import styles from "./Post.module.scss";

const Post = ({ className, children, title, date, html }) => (
  <div className={cx(styles.root, className)}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.date}>{date}</p>
    {html && <MarkdownContent html={html} />}
    {children}
  </div>
);

Post.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  html: PropTypes.string.isRequired,
};

export default Post;
